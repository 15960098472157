<template>
	<div class="user pageContent">
		<div class="user-tool-bar">
			<div class="user-msg">
				<div class="avatar">
					<img
						v-if="$store.state.user.userInfo.avatar"
						:src="$store.state.user.userInfo.avatar"
						alt=""
					/>
					<img
						v-else
						src="../../assets/img/common/avatar.png"
						alt=""
					/>
				</div>
				<div class="nick-name">
					{{ $store.state.user.userInfo.nickname }}
				</div>
				<div class="user-number iconfont icon-papa-phone">
					{{ $store.state.user.userInfo.username }}
				</div>
			</div>
			<div class="user-tab">
				<div
					class="user-tab-item"
					v-for="item in useTabData"
					:key="item.url"
				>
					<router-link :to="item.url">
						<div class="iconfont" :class="`icon-papa-${item.icon}`">
							{{ item.name }}
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="user-content">
			<!--      <keep-alive>-->
			<router-view name="user" />
			<!--      </keep-alive>-->
		</div>
	</div>
</template>

<script>
import UserJs from './User.js';

export default UserJs;
</script>

<style lang="less" scoped>
.user {
	background: #fff;
	min-height: 100vh;
	margin-top: 17px;
	border-radius: 28px;
	color: #999;
	display: flex;
	margin-bottom: 60px;

	& > .user-tool-bar {
		width: 250px;
		box-sizing: border-box;
		border-right: 1px solid #f3f3f3;
		//display: none;
		& > .user-msg {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 40px 0;

			& > .avatar {
				height: 80px;
				width: 80px;
				border-radius: 50%;
				border: 4px solid #fff;
				box-shadow: 0 6px 32px -5px rgba(105, 104, 178, 0.18);
				overflow: hidden;

				& > img {
					width: 100%;
					height: 100%;
				}
			}

			& > .nick-name {
				margin: 20px 0;
			}
		}

		& > .user-tab {
			& > .user-tab-item {
				padding-left: 70px;
				width: 100%;
				height: 50px;
				box-sizing: border-box;
				text-align: left;
				line-height: 50px;
			}

			& > .active {
				background: #f4fdf8;
				color: @papaGreen;
			}
		}
	}

	& > .user-content {
		width: 1200px;
		height: 100%;
	}
}

a {
	color: #999;
	font-weight: 400;
	text-decoration: none;

	&.router-link-exact-active {
		color: #29d087;
	}
}
</style>
