import { getLiveConfigApi } from '@/api/live/live'
import AES from '@/utils/AES.js'
let Base64 = require("js-base64").Base64;

export default {
    metaInfo: {
        title: '趴趴教育唯一官方网站 - 雅思,托福,GMAT走心的在线英语培训平台',
        meta: [{
            name: 'keywords',
            content: '趴趴雅思,趴趴托福,趴趴GMAT,趴趴雅思小助手,雅思,托福,GMAT,PTE,实用英语',
        }, ],
    },
    data() {
        return {
            useTabData: [{
                    url: '/user/schedule',
                    icon: 'personal_scl',
                    name: '我的课表',
                },
                {
                    url: '/user/course',
                    icon: 'course',
                    name: '我的课程',
                },
                {
                    url: '/user/activity',
                    icon: 'personal_activity',
                    name: '我的活动',
                },
                {
                    url: '/user/trainingSpeaking',
                    icon: 'coach',
                    name: '辅导训练',
                },
                {
                    url: '/user/coupon',
                    icon: 'coupon',
                    name: '优惠券',
                },
                {
                    url: '/user/orders/list',
                    icon: 'order',
                    name: '课程订单',
                },
                {
                    url: '/user/account',
                    icon: 'account',
                    name: '账户设置',
                },
            ],
        };
    },
    mounted() {
        this.getLiveConfigFn()
    },
    methods: {
        getLiveConfigFn() {
            let configStr = process.env.VUE_APP_LIVE_CONFIG
            getLiveConfigApi({}, (res) => {
              const { iv, value } = JSON.parse(Base64.decode(res.encrypt))
              const config = JSON.parse(AES.decrypt(value, configStr, 'CBC', iv))
              console.log(config)
              localStorage.setItem('configSDKAPPID', JSON.stringify(config.tencent_cloud.trtc.sdk_app_id))
              localStorage.setItem('configSUBAPPID', JSON.stringify(config.tencent_cloud.vod.sub_app_id))
            })
          },
    }
};